(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/swipe-arrows/assets/javascripts/swipe-arrows.js') >= 0) return;  svs.modules.push('/components/sport/swipe-arrows/assets/javascripts/swipe-arrows.js');
"use strict";


const {
  useEffect,
  useState,
  useRef
} = React;
const {
  ReactIcon
} = svs.ui;
const SwipeArrows = _ref => {
  let {
    arrowVerticalPadding = 80,
    getLeftScrollWidth,
    getRightScrollWidth,
    leftArrowClassName = '',
    rightArrowClassName = '',
    containerClassName = '',
    scrollContentEl,
    scrollParentSelector,
    leftArrowOffset = 0
  } = _ref;
  const [renderLeftArrow, setRenderLeftArrow] = useState(false);
  const [renderRightArrow, setRenderRightArrow] = useState(false);
  const [topOffset, setTopOffset] = useState(arrowVerticalPadding);
  const arrowContainerRef = useRef(null);
  const leftArrowRef = useRef(null);
  const rightArrowRef = useRef(null);
  const handleArrowClick = direction => {
    if (!scrollContentEl) {
      return;
    }
    scrollContentEl.scrollTo({
      'scroll-behavior': 'smooth',
      left: direction === 'right' ? getRightScrollWidth() : getLeftScrollWidth()
    });
  };
  useEffect(() => {
    if (scrollContentEl) {
      setTopOffset(scrollContentEl.scrollHeight / 2);
    }
  }, [scrollContentEl]);
  useEffect(() => {
    const handleArrowScrolls = () => {
      const scrollWidth = scrollContentEl.scrollWidth;
      const scrollLeft = scrollContentEl.scrollLeft;
      const holderWidth = scrollContentEl.clientWidth;
      const scrollLength = scrollWidth - holderWidth;
      if (holderWidth >= scrollWidth) {
        setRenderLeftArrow(false);
        setRenderRightArrow(false);
      } else if (scrollLeft === 0) {
        setRenderLeftArrow(false);
        setRenderRightArrow(true);
      } else if (scrollLeft >= scrollLength - 5) {
        setRenderLeftArrow(true);
        setRenderRightArrow(false);
      } else if (scrollLeft > 0) {
        setRenderLeftArrow(true);
        setRenderRightArrow(true);
      } else {
        setRenderLeftArrow(false);
        setRenderRightArrow(false);
      }
    };
    const handleArrowPlacement = () => {
      const parentScrollOffset = scrollParentSelector ? document.querySelector(scrollParentSelector).scrollTop : window.pageYOffset;
      const scrollContentHeight = scrollContentEl.scrollHeight;
      if (parentScrollOffset > arrowVerticalPadding && parentScrollOffset < scrollContentHeight - arrowVerticalPadding) {
        setTopOffset(scrollContentHeight / 2);
      }
    };
    if (!scrollContentEl) {
      return;
    }
    let resizeTimer;
    scrollContentEl.addEventListener('scroll', handleArrowScrolls);
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        handleArrowScrolls();
      }, 250);
    });
    handleArrowScrolls();
    const scrollParent = scrollParentSelector ? document.querySelector(scrollParentSelector) : window;
    scrollParent.addEventListener('scroll', handleArrowPlacement);
    return () => {
      scrollContentEl.removeEventListener('scroll', handleArrowScrolls);
      window.removeEventListener('resize', handleArrowScrolls);
      scrollParent.removeEventListener('scroll', handleArrowPlacement);
    };
  }, [arrowVerticalPadding, scrollContentEl, scrollParentSelector]);
  return React.createElement("div", {
    className: "swipe-arrow-container ".concat(containerClassName),
    ref: arrowContainerRef,
    style: {
      transform: "translateY(".concat(topOffset, "px)")
    }
  }, renderLeftArrow && React.createElement("div", {
    className: "left-arrow ".concat(leftArrowClassName),
    onClick: () => handleArrowClick('left'),
    ref: leftArrowRef,
    style: {
      transform: "translateX(".concat(leftArrowOffset, "px)")
    }
  }, React.createElement(ReactIcon, {
    color: "fc-black",
    icon: "badge-arrow-left",
    size: "600"
  })), renderRightArrow && React.createElement("div", {
    className: "right-arrow ".concat(rightArrowClassName),
    onClick: () => handleArrowClick('right'),
    ref: rightArrowRef
  }, React.createElement(ReactIcon, {
    color: "fc-black",
    icon: "badge-arrow-right",
    size: "600"
  })));
};
svs.sport.swipeArrows.SwipeArrows = SwipeArrows;

 })(window);